<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Add Partner">
        <div class="document-toolbar">
          <div class="document-actions">
            <div class="button-group">
              <button class="button" :disabled="validation.pristine || validation.invalid" @click="handleSubmit">Save</button>
              <a class="button secondary" @click="$router.back()">Cancel</a>
            </div>
          </div>
        </div>
      </sticky-header>
    </template>
    <template>
      <div v-if="!$apollo.loading" class="grid">
        <div class="col-7">
          <div class="spread">
            <div class="module-form">
              <h3>BASIC INFO</h3>
              <div class="para-group">
                <form-input v-model="form.firstName" name="First Name" label="First Name" placeholder="First Name" required />
                <form-input v-model="form.lastName" label="Last Name" placeholder="Last Name" />
                <form-input v-model="form.phone" :mask="true" name="Phone" rules="phone" label="Phone Number" type="phone" placeholder="Phone Number" required />
                <form-input v-model="form.email" name="Email" label="Email" type="email" placeholder="Email" required />
              </div>
            </div>
            <div class="module-form">
              <h3>ADDRESS</h3>
              <div class="para-group">
                <form-input v-model="form.address.data.address1" name="address" label="Street Address" required />
                <form-input v-model="form.address.data.address2" name="address" label="Street Address" />
                <form-input v-model="form.address.data.city" name="city" label="City" required />
                <div class="field">
                  <label
                    >State
                    <region-select
                      v-model="form.address.data.state"
                      :country="form.address.data.country"
                      :region="form.address.data.state"
                      placeholder="Select State"
                      autocomplete
                      disable-placeholder
                    />
                  </label>
                </div>
                <form-input v-model="form.address.data.postalCode" name="zip" label="Zip" />
                <div class="field">
                  <label
                    >Country
                    <country-select v-model="form.address.data.country" :country="form.address.data.country" top-country="US" autocomplete />
                  </label>
                </div>
              </div>
            </div>
            <div class="module-form">
              <h3>DRIVERS LICENSE</h3>
              <p class="label">Drivers License State</p>
              <form-input v-model="driversLicenses.state" placeholder="Drivers License State" />
              <p class="label">Drivers License Number</p>
              <form-input v-model="driversLicenses.number" placeholder="Drivers License Number" />
              <p class="label">Drivers License Expiration</p>
              <form-input v-model="driversLicenses.expiration" placeholder="Drivers License Expiration" />
            </div>
            <div class="module-form">
              <h3>PERSONAL IDENTIFYING</h3>
              <p class="label">Date of Birth</p>
              <form-input v-model="form.dateOfBirth" placeholder="Date of Birth" name="Date of Birth" />
              <p class="label">EIN</p>
              <form-input v-model="form.ein" placeholder="EIN" />
              <p class="label">Social Security Number</p>
              <form-input v-model="form.ssn" placeholder="SSN" name="SSN" required />
            </div>
          </div>
        </div>
      </div>
    </template>
  </add-edit-page>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import AddEditPage from "@/components/layout/AddEditPage";
import { ADD_PARTNER } from "@/modules/admin/partners/graph/mutation";

export default {
  name: "PartnerProfileEdit",
  components: { AddEditPage, FormInput, StickyHeader },
  data: function () {
    return {
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        address: {
          data: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
          },
        },
        dateOfBirth: null,
        ein: "",
        ssn: "",
        driversLicenses: {
          data: [],
        },
      },
      driversLicenses: {
        state: "",
        number: "",
        expiration: null,
      },
    };
  },
  methods: {
    handleSubmit() {
      this.form.email = this.form.email.toLowerCase();
      this.form.phone = this.form.phone.replace(/\D/g, "");
      this.form.driversLicenses.data.push(this.driversLicenses);
      this.$apollo
        .mutate({
          mutation: ADD_PARTNER,
          variables: {
            object: this.form,
          },
        })
        .then(() => this.$router.back());
    },
  },
};
</script>
